<script setup lang="ts" generic="T extends Object">
import { mdiDotsVertical } from "@mdi/js";
import type { DropdownAction } from "~/types/DropdownAction";

interface Props {
  actions: DropdownAction<T>[];
  item: T;
}
const props = withDefaults(defineProps<Props>(), {
  actions: () => [],
});

const filteredActions = computed(() => {
  return props.actions?.filter((a) => !a.hidden || !a.hidden(props.item as T));
});
</script>

<template>
  <VMenu v-if="actions.length" :disabled="!filteredActions.length">
    <template #activator="{ props: activatorProps }">
      <VBtn
        :icon="true"
        :disabled="!filteredActions.length"
        density="comfortable"
        variant="text"
        v-bind="activatorProps"
      >
        <VIcon color="grey-darken-2" :icon="mdiDotsVertical" />
      </VBtn>
    </template>
    <slot name="actions" :item="item">
      <VList>
        <VListItem
          v-for="element in filteredActions"
          :key="element.label"
          :disabled="element.disabled && element.disabled(item as T)"
          @click="element.call(item as T)"
        >
          <VListItemTitle>{{ element.label }}</VListItemTitle>
        </VListItem>
      </VList>
    </slot>
  </VMenu>
</template>
